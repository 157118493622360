import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import SearchBlock from "components/search/SearchBlock";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { autobind } from "react-decoration";
import { page } from "components/page";
import Link from "lib/Link";

const getNewsDetail = gql`
  query getNewsDetail($form: NewsForm) {
    result: getNewsDetail(Input: $form) {
      sn
      subject
      content
      url
      cretime
      updtime
      attachs {
        attachName
        showName
        attachPath
      }
    }
  }
`;

@withI18next(["common"])
@page
class NewsDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceType: "databases",
      pageContent: "",
    };
  }

  componentDidMount() {
    //console.log(this.props);
    this.getDetail(this.props);
  }

  @autobind
  getDetail(props) {
    //console.log("props.search.sn="+props.search.sn);
    client.jumperrwdClient
      .query({
        query: getNewsDetail,
        variables: {
          form: {
            sn: props.search.sn,
          },
        },
      })
      .then((res) => {
        const { result } = res.data;
        console.log(result);

        this.setState({ pageContent: result });
      });
  }

  render() {
    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accessKey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <h2>{this.props.t("jumper.common.news.titlebar")}</h2>
              <a href="javascript:history.back();" className="back">
                {this.props.t("hyint.common.table.back")}
              </a>
              <div className="detail_block">
                <h3>{this.state.pageContent.subject}</h3>
                <div className="date_panel">
                  <div className="publish_time">
                    <span>{this.props.t("hyint.common.table.credate")}</span>
                    <time>{this.state.pageContent.cretime}</time>
                  </div>
                  <div className="publish_time">
                    <span>{this.props.t("hyint.common.table.upddate")}</span>
                    <time>{this.state.pageContent.updtime}</time>
                  </div>
                </div>
                <div className="article_content">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.pageContent.content,
                    }}></p>

                  {this.state.pageContent.url && (
                    <p>
                      {this.props.t("hyint.common.table.relurl")}：
                      {this.state.pageContent.url
                        .split("\n")
                        .map((items, i) => {
                          let data = items.split(";");
                          if (data[0] === "1") {
                            return (
                              <p>
                                <Link href={data[2]} target="_blank">
                                  {data[1]}
                                </Link>
                              </p>
                            );
                          } else {
                            return (
                              <p>
                                <Link href={data[2]}>{data[1]}</Link>
                              </p>
                            );
                          }
                        })}
                    </p>
                  )}
                  {this.state.pageContent.attachs &&
                    this.state.pageContent.attachs.length > 0 && (
                      <p>
                        {this.props.t("jumperrwd.download.relatedDownload")}：
                        {this.state.pageContent.attachs.map((item, i) => {
                          return (
                            <p>
                              <Link
                                target="_blank"
                                href={"/file/downloadFile/" + item.attachPath}>
                                {item.showName}
                              </Link>
                            </p>
                          );
                        })}
                      </p>
                    )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default NewsDetail;
